import { getHitTemplates } from './getHitTemplates'

export function getInfiniteHitsParams (container, defaultIndex, config, cm_typesense_instant_search_default_settings, localized_strings) {
  let hitTemplates = getHitTemplates(defaultIndex, cm_typesense_instant_search_default_settings)
  hitTemplates.showMoreText = localized_strings.load_more

  return {
    container: container,
    escapeHTML: true,
    templates: hitTemplates,
    cssClasses: {
      list: 'cm-col-' + config.columns
    }
  }
}