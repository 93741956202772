export default function getClearRefinements (container) {
  if (container === null) return
  let clearText = container.getAttribute('data-label')
  let templates = {
    resetLabel: clearText,
  }

  let args = {
    container: container,
    templates: templates,
  }

  return args

}