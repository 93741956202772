const hideFilterPanelHandler = (searchWrapperEl, $hide = false) => {
  //console.log($hide)
  const filterPanel = searchWrapperEl.querySelector('.cmswt-FilterPanel')
  const mainPanel = searchWrapperEl.querySelector('.cmswt-MainPanel')
  const filterToggle = searchWrapperEl.querySelector('.cmswt-FilterPanel-toggle')

  if ($hide) {
    if (filterToggle !== null)
      filterToggle.classList.add('cmswt-FilterPanel-toggle--hidden')

    if (filterPanel !== null)
      filterPanel.classList.add('cmswt-FilterPanel--hidden')

    if (mainPanel !== null)
      mainPanel.classList.add('cmswt-MainPanel--fullWidth')

  } else {
    if (filterPanel !== null)
      filterPanel.classList.remove('cmswt-FilterPanel--hidden')

    if (filterToggle !== null)
      filterToggle.classList.remove('cmswt-FilterPanel-toggle--hidden')

    if (mainPanel !== null)
      mainPanel.classList.remove('cmswt-MainPanel--fullWidth')
  }

}

export function onRenderHandler (searchWrapperEl, index) {
  let facetEls = searchWrapperEl.querySelectorAll(`.cmswt-Filter-collection_${index}`)
  let allEmpty = true
  //will only work if there is no schema defined, won't work for empty facets
  if (facetEls.length) {
    facetEls.forEach((facetEl) => {
      if (facetEl.hasChildNodes()) {
        //this only works if there are no facets in the schema
        allEmpty = false
      }
    })
  }

  //if empty we need to hide the filter panel
  hideFilterPanelHandler(searchWrapperEl, allEmpty)
  allEmpty = true
}