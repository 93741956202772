export function getSearchBoxParams (container, placeholder) {
  let searchBoxDefaultSetting = {
    container: container,
    autofocus: true,
    placeholder: placeholder,
    showSubmit: false,
    showReset: false
  }

  let searchBoxCustomSettings = JSON.parse(container.dataset.settings)

  return { ...searchBoxDefaultSetting, ...searchBoxCustomSettings }
}