export function getConfigureParams (config, searchQuery) {
  //configure widget [hitsPerPage] https://www.algolia.com/doc/api-reference/widgets/configure/js/
  let configureSettings = {
    hitsPerPage: config.per_page,
  }

  if (searchQuery !== null) {
    configureSettings.query = searchQuery
  }

  return configureSettings
}