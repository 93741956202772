import { getHitTemplates } from './getHitTemplates'

export function getHitsParams (container, defaultIndex, config, cm_typesense_instant_search_default_settings) {
  return {
    container: container,
    escapeHTML: true,
    templates: getHitTemplates(defaultIndex, cm_typesense_instant_search_default_settings,),
    cssClasses: {
      list: 'cm-col-' + config.columns
    }
  }
}