export function getCurrentRefinementsParams (currentRefinementsEl, index, searchWrapperEl) {
	return {
		container: currentRefinementsEl,
		excludedAttributes: ['lang_attribute_filter'],
		transformItems: (items) => {
			return items.map(
				(item) => {
					let $attributeEl = searchWrapperEl.querySelector(`[data-facet_id="${index}_${item.attribute}"]`)
					let label = item.label
					if ($attributeEl != null) {
						let dataLabel = $attributeEl.getAttribute('data-label')
						if (dataLabel !== null) {
							label = dataLabel
						}
					}
					return { ...item, label: label }
				},
			)
		},
	}
}