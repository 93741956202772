export default function getStats (statsEl = null) {
  if (statsEl === null) return

  let singularText = statsEl.getAttribute('data-singular')
  let pluralText = statsEl.getAttribute('data-plural')
  let noResultsText = statsEl.getAttribute('data-no_results')
  return {
    container: statsEl,
    templates: {
      text (data, { html }) {
        let count = ''

        if (data.hasManyResults) {
          count += `${data.nbHits} ${pluralText}`
        } else if (data.hasOneResult) {
          count += `1 ${singularText}`
        } else {
          count += `no ${noResultsText}`
        }

        return html`<span>${count}</span>`
      },
    },
  }

}