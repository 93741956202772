export function mobileFilter (searchWrapperEl) {
  const filterWrapperOpenTrigger = searchWrapperEl.querySelector('.cmswt-FilterPanel-toggle')
  const filterWrapperCloseTriggers = searchWrapperEl.querySelectorAll('.cmswt-FilterPanel-itemsClose')

  const resizeHandler = () => {
    if (window.innerWidth > 575) {
      closeItemsPopup()
      window.removeEventListener('resize', resizeHandler)
    }
  }

  const closeItemsPopup = () => {
    searchWrapperEl.classList.remove('cmswt-InstantSearch--filterPanelOpen')
    searchWrapperEl.querySelector('.cmswt-FilterPanel-items').classList.remove('cmswt-FilterPanel-items--show')
  }
  const filterItemsOpen = (e) => {
    e.preventDefault()
    searchWrapperEl.classList.add('cmswt-InstantSearch--filterPanelOpen')
    searchWrapperEl.querySelector('.cmswt-FilterPanel-items').classList.add('cmswt-FilterPanel-items--show')
    window.addEventListener('resize', resizeHandler)
  }
  const filterItemsClose = (e) => {
    e.preventDefault()
    closeItemsPopup()
  }

  if (filterWrapperOpenTrigger !== null) {
    filterWrapperOpenTrigger.addEventListener('click', filterItemsOpen)
  }

  if (filterWrapperCloseTriggers.length > 0) {
    filterWrapperCloseTriggers.forEach((filterWrapperCloseTrigger) => {
      filterWrapperCloseTrigger.addEventListener('click', filterItemsClose)
    })
  }

}

export function toggleCollectionSwitcherMenuHandler (searchWrapperEl) {
  const activeCollectionEl = searchWrapperEl.querySelector('.cmswt-CollectionMenu-current')
  const indexSwitcher = searchWrapperEl.querySelector('.cmswt-IndexSwitcher')
  let isOpen = false

  if (activeCollectionEl === null || indexSwitcher === null)
    return false

  const windowResizeHandler = () => {
    if (window.innerWidth > 575) {
      closeCollectionSwitcherMenu()
      window.removeEventListener('resize', windowResizeHandler)
    }
  }
  const closeCollectionSwitcherMenu = () => {
    if (isOpen) {
      isOpen = false
      activeCollectionEl.classList.remove('cmswt-CollectionMenu-current--listOpen')
      indexSwitcher.classList.remove('cmswt-IndexSwitcher--block')
      activeCollectionEl.addEventListener('click', expandCollectionSwitcherMenu, { once: true })
      document.removeEventListener('click', closeCollectionSwitcherMenu, { once: true })
    }

  }
  const expandCollectionSwitcherMenu = () => {
    activeCollectionEl.classList.add('cmswt-CollectionMenu-current--listOpen')
    indexSwitcher.classList.add('cmswt-IndexSwitcher--block')
    //adding eventListener immediately causes the listener to run too soon - that's why i need a timeout of 200ms
    setTimeout(() => {
      document.addEventListener('click', closeCollectionSwitcherMenu, { once: true })
    }, 200)
    window.addEventListener('resize', windowResizeHandler)
    isOpen = true
  }

  activeCollectionEl.addEventListener('click', expandCollectionSwitcherMenu, { once: true })
}