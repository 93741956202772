import { highlight, snippet } from 'instantsearch.js/es/helpers'
import { date } from '@wordpress/date'

export function getHitTemplates (
  defaultIndex, cm_typesense_instant_search_default_settings) {
  function isDateFormat (inputString) {
    const pattern = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/
    return pattern.test(inputString)
  }

  const getFormattedHitItem = (hit) => {
    let postedDate = hit.post_date
    if (isDateFormat(hit.post_date)) {
        postedDate = date(cm_typesense_instant_search_default_settings.date_format, hit.post_date)
    }

    hit.formatted = {
      post_title: highlight(
        { attribute: 'post_title', highlightedTagName: 'mark', hit }),
      post_content: snippet(
        { attribute: 'post_content', highlightedTagName: 'mark', hit }),
      postedDate: postedDate,
    }

    //Avoiding too many text as well as not removing matched snippets
    //maybe there is a better way ?
    const regex = /<mark/gs
    if (hit.formatted.post_content.match(regex) === null) {
      hit.formatted.post_content = hit.formatted.post_content.substring(0,
        100) + '...'
    }

    if (hit.hasOwnProperty('cat_link')) {
      hit.formatted.cats = hit.cat_link.reduce((result, field, index) => {
        result[hit.category[index]] = field
        return result
      }, {})
    }

    return hit
  }

  return {
    empty: () => {
      return wp.template('cmswt-NoSearchResults-default')()
    },
    item (hit) {
      let hitTemplate = document.getElementById(
        'tmpl-cmswt-Result-itemTemplate--' + defaultIndex)
      let formattedData = getFormattedHitItem(hit)
      if (hitTemplate !== null) {
        return wp.template('cmswt-Result-itemTemplate--' + defaultIndex)(
          formattedData)
      } else {
        return wp.template('cmswt-Result-itemTemplate--default')(formattedData)
      }
    },
  }
}