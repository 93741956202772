export function getSortByParams (defaultIndex, sortByEl) {
  let defaultSortBySettings = {
    items: [
      { label: 'Recent', value: `${defaultIndex}` },
      { label: 'Oldest', value: `${defaultIndex}/sort/sort_by_date:asc` }
    ],
  }
  let sortByCustomSettings = JSON.parse(sortByEl.dataset.settings)
  let sortByCombinedSettings = { ...defaultSortBySettings, ...sortByCustomSettings }
  //we're not allowing container to be changed
  sortByCombinedSettings.container = sortByEl
  return sortByCombinedSettings
}