export function analyticsMiddleware () {
  return {
    onStateChange ({ uiState }) {
      window.SearchWithTypesenseState = uiState
      const event = new CustomEvent('cmswt_search_state_changed', { detail: uiState })
      document.dispatchEvent(event)
    },
    subscribe () {},
    unsubscribe () {},
  }
}