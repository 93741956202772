import { menu, menuSelect, panel, rangeInput, rangeSlider, refinementList, ratingMenu } from 'instantsearch.js/es/widgets'

export function getFacetParams (filterItem, facet, localized_strings) {
  let filterPanel = ''
  let title = filterItem.dataset.title
  let filterType = filterItem.dataset.filter_type

  let panelWidgetParams = {
    templates: {
      header: title,
    },
    collapsed () {
      return window.innerWidth < 768
    },
    hidden (options) {
      return options.results.facets_stats[facet]?.total_values === 0
    }
  }

  let filterConfig = {}
  let filterDefaultSettings = {
    searchable: false,
    searchablePlaceholder: `Search ${facet}`,
    showMore: true,
    limit: 10,
    showMoreLimit: 100,
    templates: {
      showMoreText: `
              {{#isShowingMore}}
                ${localized_strings.show_less}
              {{/isShowingMore}}
              {{^isShowingMore}}
                ${localized_strings.show_more}
              {{/isShowingMore}}
            `,
    },
    cssClasses: {
      searchableSubmit: 'd-none',
      searchableReset: 'd-none',
    }
  }

  let filterCustomSettings = JSON.parse(filterItem.dataset.settings)

  filterConfig = { ...filterDefaultSettings, ...filterCustomSettings }

  //container and attribute not overridable via custom settings
  filterConfig.container = filterItem
  filterConfig.attribute = facet
  switch (filterType) {
    case 'menu':
      let menuWithPanel = panel(panelWidgetParams)(menu)
      filterPanel = menuWithPanel(filterConfig)
      break
    case 'rangeInput':
      let rangeInputWithPanel = panel(panelWidgetParams)(rangeInput)
      filterPanel = rangeInputWithPanel(filterConfig)
      break
    case 'ratingMenu':
      let ratingInputWithPanel = panel(panelWidgetParams)(ratingMenu)
      filterPanel = ratingInputWithPanel(filterConfig)
      break
    case 'rangeSlider':
      let rangeSliderWithPanel = panel(panelWidgetParams)(rangeSlider)
      filterConfig.pips = false
      filterPanel = rangeSliderWithPanel(filterConfig)
      break
    case 'menuSelect':
      let menuSelectWithPanel = panel(panelWidgetParams)(menuSelect)
      filterPanel = menuSelectWithPanel(filterConfig)
      break
    default:
      let refinementListWithPanel = panel(panelWidgetParams)(refinementList)
      filterPanel = refinementListWithPanel(filterConfig)
  }

  return filterPanel
}